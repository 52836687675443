<template>
  <v-container fluid v-if="!loading">
    <!-- Painel de Pendentes -->
    <v-expansion-panels flat v-model="painelAgendaPendente" class="mt-8">
      <v-expansion-panel>
        <v-expansion-panel-header class="title toolbar--text">
          Pendentes
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <template v-if="agendaPendente.length">
              <v-col
                cols="12"
                md="3"
                v-for="evento in agendaPendente"
                :key="evento.id"
              >
                <v-expansion-panels class="mt-4 mini-card-evento">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      hide-actions
                      style="max-height: 48px !important; min-height: 42px !important; "
                      class="caption pa-2"
                      color="lightgray"
                    >
                      {{ excerpt(evento.cliente) }}
                      <v-spacer> </v-spacer>
                      <v-btn
                        icon
                        max-width="40px"
                        color="anchors"
                        @click.stop="goToEvento(evento.id)"
                      >
                        <v-icon>launch</v-icon>
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-card flat>
                        <!-- Protocolo -->
                        <v-card-text
                          v-if="evento.protocolo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" color="accent" small
                                >horizontal_split</v-icon
                              >
                              {{ evento.protocolo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Tipo do Evento -->
                        <v-card-text
                          v-if="evento.eventotipo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >gavel</v-icon
                              >
                              {{ evento.eventotipo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Agendamento -->
                        <v-card-text
                          v-if="evento.agendado_data && evento.agendado_hora"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >event</v-icon
                              >
                              <div>
                                {{
                                  evento.agendado_data | moment("DD/MM/YYYY")
                                }}
                                {{ evento.agendado_hora ? "as" : "" }}
                                {{ evento.agendado_hora }}
                              </div>
                            </div>
                          </v-chip>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12">
                Nenhum evento pendente
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Painel de Agenda Diaria -->
    <v-expansion-panels flat v-model="painelAgendaDiaria" class="mt-8">
      <v-expansion-panel>
        <v-expansion-panel-header class="title toolbar--text">
          Agenda Diaria
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <template v-if="agendaDiaria.length">
              <v-col
                cols="12"
                md="3"
                v-for="evento in agendaDiaria"
                :key="evento.id"
              >
                <v-expansion-panels class="mt-4 mini-card-evento">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      hide-actions
                      style="max-height: 48px !important; min-height: 42px !important; "
                      class="caption pa-2"
                      color="lightgray"
                    >
                      {{ excerpt(evento.cliente) }}
                      <v-spacer> </v-spacer>
                      <v-btn
                        icon
                        max-width="40px"
                        color="anchors"
                        @click.stop="goToEvento(evento.id)"
                      >
                        <v-icon>launch</v-icon>
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-card flat>
                        <!-- Protocolo -->
                        <v-card-text
                          v-if="evento.protocolo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" color="accent" small
                                >horizontal_split</v-icon
                              >
                              {{ evento.protocolo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Tipo do Evento -->
                        <v-card-text
                          v-if="evento.eventotipo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >gavel</v-icon
                              >
                              {{ evento.eventotipo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Agendamento -->
                        <v-card-text
                          v-if="evento.agendado_data && evento.agendado_hora"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >event</v-icon
                              >
                              <div>
                                {{
                                  evento.agendado_data | moment("DD/MM/YYYY")
                                }}
                                {{ evento.agendado_hora ? "as" : "" }}
                                {{ evento.agendado_hora }}
                              </div>
                            </div>
                          </v-chip>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12">
                Nenhum evento agendado.
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Painel de Agenda Futura -->
    <v-expansion-panels flat v-model="painelAgendaFutura" class="mt-8">
      <v-expansion-panel>
        <v-expansion-panel-header class="title toolbar--text">
          Agenda Futura
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <template v-if="agendaDiaria.length">
              <v-col
                cols="12"
                md="3"
                v-for="evento in agendaFutura"
                :key="evento.id"
              >
                <v-expansion-panels class="mt-4 mini-card-evento">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      hide-actions
                      style="max-height: 48px !important; min-height: 42px !important; "
                      class="caption pa-2"
                      color="lightgray"
                    >
                      {{ excerpt(evento.cliente) }}
                      <v-spacer> </v-spacer>
                      <v-btn
                        icon
                        max-width="40px"
                        color="anchors"
                        @click.stop="goToEvento(evento.id)"
                      >
                        <v-icon>launch</v-icon>
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-card flat>
                        <!-- Protocolo -->
                        <v-card-text
                          v-if="evento.protocolo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" color="accent" small
                                >horizontal_split</v-icon
                              >
                              {{ evento.protocolo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Tipo do Evento -->
                        <v-card-text
                          v-if="evento.eventotipo"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >gavel</v-icon
                              >
                              {{ evento.eventotipo }}
                            </div>
                          </v-chip>
                        </v-card-text>

                        <!-- Agendamento -->
                        <v-card-text
                          v-if="evento.agendado_data && evento.agendado_hora"
                          class="px-0 pt-0 pb-0"
                        >
                          <v-chip
                            class="pa-2 chip100"
                            style="width: 100%"
                            color="white"
                            label
                          >
                            <div
                              style="width: 100%"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-icon class="mr-2" :color="evento.cor" small
                                >event</v-icon
                              >
                              <div>
                                {{
                                  evento.agendado_data | moment("DD/MM/YYYY")
                                }}
                                {{ evento.agendado_hora ? "as" : "" }}
                                {{ evento.agendado_hora }}
                              </div>
                            </div>
                          </v-chip>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12">
                Nenhum evento futuro
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Dialog para abertura do evento -->
    <v-dialog
      @keydown.esc="voltarEventos"
      v-model="dialog"
      @click:outside="voltarEventos"
      width="100%"
      max-width="900px"
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <router-view></router-view>
    </v-dialog>
  </v-container>
</template>

<script>
import EventosPainelApi from "@/services/api/eventos-painel.api.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "Painel",
  data: () => ({
    painelAgendaPendente: null,
    painelAgendaDiaria: null,
    painelAgendaFutura: null,
    agendaPendente: [],
    agendaDiaria: [],
    agendaFutura: [],
    loading: true,
    dialog: false,
  }),
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.dialog = newVal.meta && newVal.meta.dialog;
      },
    },
  },
  methods: {
    excerpt(text) {
      if (!text) return;
      if (text.length > 25) {
        return text.substr(0, 25) + "...";
      } else {
        return text;
      }
    },
    goToEvento(id) {
      this.$router.push({
        path: `atendimentos/${id}`,
      });
    },
    voltarEventos() {
      this.$router.push({
        name: "PainelMeusAtendimentos",
      });
    },
    getEventosPainel() {
      EventosPainelApi.getMeusAtendimentos(this.user_id)
        .then((response) => {
          this.agendaPendente = response.pendentes;
          this.agendaDiaria = response.agenda_diaria;
          this.agendaFutura = response.agenda_futura;

          if (this.agendaPendente.length) this.painelAgendaPendente = 0;
          if (this.agendaDiaria.length) this.painelAgendaDiaria = 0;
          if (this.agendaFutura.length) this.painelAgendaFutura = 0;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getEventosPainel();
  },
  beforeCreate() {
    this.$Progress.start();
  },
};
</script>

<style lang="scss"></style>
